import React from 'react';
import {connect} from 'react-redux';
import {Button, Divider, Form, Label, Segment} from 'semantic-ui-react';
import {Field, reduxForm} from 'redux-form';
import {
    combineValidators,
    composeValidators,
    createValidator,
    hasLengthGreaterThan,
    isRequired,
    matchesField
} from 'revalidate';
import TextInput from '../../../components/form/TextInput';
import {registerUser, socialLogin} from '../authActions';
import SocialLogin from '../SocialLogin/SocialLogin';
import DateInput from "../../../components/form/DateInput";
import {firestore} from "../../../config/firebase";
import SelectInput from "../../../components/form/SelectInput";
import {addYears} from "date-fns";

import 'semantic-ui-css/components/form.min.css'
import 'semantic-ui-css/components/label.min.css'
import 'semantic-ui-css/components/button.min.css'
import 'semantic-ui-css/components/divider.min.css'
import 'semantic-ui-css/components/segment.min.css'
import {collection, getDocs, query, where} from "firebase/firestore/lite";

const actions = {
    registerUser,
    socialLogin
};

const isValidEmail = createValidator(
    message => value => {
        if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
            return message
        }
    },
    'invalid email address'
)

const validate = combineValidators({
    username: isRequired('username'),
    email: composeValidators(
        isRequired('email'),
        isValidEmail
    )(),
    password: composeValidators(
        isRequired('password'),
        hasLengthGreaterThan(5)({
            message: 'must be 6 characters or more'
        })
    )(),
    confirmPassword: composeValidators(
        isRequired({message: 'please confirm your password'}),
        matchesField('password')({
            message: 'passwords do not match',
        }))(),
    dateOfBirth: isRequired('dateOfBirth'),
});

const asyncValidate = async (values) => {

    const usersRef = collection(firestore, 'users');
    const queryCondition = query(usersRef, where("username", "==", values.username))


    let existingUsernames = await getDocs(queryCondition)
    if (!existingUsernames.empty) {
        throw {username: 'That username is taken'}
    }
}

const genderOptions = [
    {key: 'm', text: 'Male', value: 'Male'},
    {key: 'f', text: 'Female', value: 'Female'}
]

const RegisterForm = ({
                          handleSubmit,
                          registerUser,
                          socialLogin,
                          error,
                          invalid,
                          submitting
                      }) => {
    return (
        <div>
            <Form
                size='large'
                autoComplete='off'
                onSubmit={handleSubmit(registerUser)}
            >
                <Segment>
                    <Field
                        name='username'
                        type='text'
                        component={TextInput}
                        placeholder='Username'
                    />
                    <Field
                        name='email'
                        type='text'
                        component={TextInput}
                        placeholder='Email'
                    />
                    <Field
                        name='password'
                        type='password'
                        component={TextInput}
                        placeholder='Password'
                    />
                    <Field
                        name='confirmPassword'
                        type='password'
                        component={TextInput}
                        placeholder='Re-enter Password'
                    />
                    <Field
                        name='gender'
                        component={SelectInput}
                        options={genderOptions}
                        placeholder='Gender'
                    />
                    <Field
                        width={8}
                        name='dateOfBirth'
                        component={DateInput}
                        dateFormat='dd LLL yyyy'
                        showYearDropdown={true}
                        showMonthDropdown={true}
                        dropdownMode='select'
                        placeholder='Date of Birth'
                        maxDate={addYears(new Date(), -10)}
                    />
                    {error && (
                        <Label basic color='red'>
                            {error}
                        </Label>
                    )}
                    <Button
                        disabled={invalid || submitting}
                        fluid
                        size='large'
                        color='teal'
                    >
                        Register
                    </Button>
                    <Divider horizontal>Or</Divider>
                    <SocialLogin socialLogin={socialLogin}/>
                </Segment>
            </Form>
        </div>
    );
};

export default connect(
    null,
    actions
)(reduxForm({form: 'registerForm', validate, asyncValidate})(RegisterForm));
