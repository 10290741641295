import React from 'react'
import {Form, Label} from 'semantic-ui-react'

import 'semantic-ui-css/components/form.min.css'
import 'semantic-ui-css/components/label.min.css'

const SelectInput = ({input, type, placeholder, multiple, options, meta: {touched, error}}) => {
    return (
        <Form.Field error={touched && !!error}>
            <select value={input.value || placeholder} onChange={(e) => input.onChange(e.target.value)}>
                {options.map((e, key) => {
                    return <option key={key} value={e.value}>{e.text}</option>;
                })}
            </select>
            {touched && error && <Label basic color='red'>{error}</Label>}
        </Form.Field>
    )
}

export default SelectInput
