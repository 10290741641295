import React from 'react';
import {connect} from 'react-redux';
import {closeModal} from './modalActions';
import {Modal} from "react-bootstrap";
import EditProfileForm from "../../screens/sidemenu/profile/EditProfileForm";
import {useUser} from "../../context/userContext";

const actions = {closeModal};

const EditProfileModal = ({closeModal}) => {
    const {user} = useUser()
    return (
        <Modal size='mini' show={true} onHide={closeModal} centered>
            <Modal.Header>
                <Modal.Title>
                    Edit Profile!
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <EditProfileForm initialValues={user}/>
            </Modal.Body>
        </Modal>
    );
}

export default connect(
    null,
    actions
)(EditProfileModal);
