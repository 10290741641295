import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import TextInput from '../../../components/form/TextInput';
import SelectInput from "../../../components/form/SelectInput";
import {updateProfile} from "./ProfileActions";

import 'semantic-ui-css/components/form.min.css'
import 'semantic-ui-css/components/divider.min.css'
import 'semantic-ui-css/components/button.min.css'
import 'semantic-ui-css/components/segment.min.css'

import {Button, Divider, Form, Segment} from 'semantic-ui-react';
import TextArea from "../../../components/form/TextArea";

const actions = {
    updateProfile,
};


const genderOptions = [
    {key: 'm', text: 'Male', value: 'Male'},
    {key: 'f', text: 'Female', value: 'Female'}
]

const EditProfileForm = ({
                             handleSubmit,
                             updateProfile,
                             pristine,
                             submitting
                         }) => {
    return (
        <div>
            <Form
                size='large'
                autoComplete='off'
                onSubmit={handleSubmit(updateProfile)}
            >
                <Segment>
                    <Field
                        name='username'
                        type='text'
                        component={TextInput}
                        placeholder='Username'
                    />
                    <Field
                        name='bio'
                        type='text'
                        component={TextArea}
                        placeholder='Bio'
                    />
                    <Field
                        name='gender'
                        component={SelectInput}
                        options={genderOptions}
                        placeholder='Gender'
                    />
                    {/*<Field*/}
                    {/*    width={8}*/}
                    {/*    name='dob'*/}
                    {/*    component={DateInput}*/}
                    {/*    dateFormat='dd LLL yyyy'*/}
                    {/*    showYearDropdown={true}*/}
                    {/*    showMonthDropdown={true}*/}
                    {/*    dropdownMode='select'*/}
                    {/*    placeholder='Date of Birth'*/}
                    {/*    maxDate={addYears(new Date(), -10)}*/}
                    {/*/>*/}
                    <Divider/>
                    <Button
                        disabled={pristine || submitting}
                        size='large'
                        positive
                        content='Update Profile'
                    />
                </Segment>
            </Form>
        </div>
    );
};

export default connect(
    null,
    actions
)(reduxForm({
    form: 'userProfile',
    // enableReinitialize: true,
    // destroyOnUnmount: true,
})(EditProfileForm));

