import React from 'react';
import {Button, Form, Label, Segment} from 'semantic-ui-react';
import {Field, reduxForm} from 'redux-form';
import TextInput from '../../../components/form/TextInput';
import {resetPassword} from '../authActions';
import {connect} from 'react-redux';

import 'semantic-ui-css/components/form.min.css'
import 'semantic-ui-css/components/label.min.css'
import 'semantic-ui-css/components/button.min.css'
import 'semantic-ui-css/components/segment.min.css'

const actions = {
    resetPassword
};

const ResetPasswordForm = ({resetPassword, handleSubmit, error}) => {
    return (
        <Form size='large' onSubmit={handleSubmit(resetPassword)} autoComplete='off'>
            <Segment>
                <Field
                    name='email'
                    component={TextInput}
                    type='text'
                    placeholder='Email Address'
                />
                {error && <Label basic color='red'>{error}</Label>}
                <Button fluid size='large' color='teal'>
                    Reset Password
                </Button>
            </Segment>
        </Form>
    );
};

export default connect(
    null,
    actions
)(reduxForm({form: 'resetPasswordForm'})(ResetPasswordForm));
