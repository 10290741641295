import React from 'react';
import {Button, Divider, Form, Label, Segment} from 'semantic-ui-react';
import {Field, reduxForm} from 'redux-form';
import TextInput from '../../../components/form/TextInput';
import {login, socialLogin} from '../authActions';
import {connect} from 'react-redux';
import SocialLogin from '../SocialLogin/SocialLogin';
import {combineValidators, isRequired} from "revalidate";
import 'semantic-ui-css/components/form.min.css'
import 'semantic-ui-css/components/label.min.css'
import 'semantic-ui-css/components/button.min.css'
import 'semantic-ui-css/components/divider.min.css'
import 'semantic-ui-css/components/segment.min.css'
import {openModal} from "../../modals/modalActions";

const actions = {
    login,
    socialLogin,
    openModal
};

const validate = combineValidators({
    email: isRequired('email'),
    password: isRequired('password'),

});

const LoginForm = ({login, handleSubmit, error, socialLogin, openModal}) => {
    return (
        <Form size='large' onSubmit={handleSubmit(login)} autoComplete='off'>
            <Segment>
                <Field
                    name='email'
                    component={TextInput}
                    type='text'
                    placeholder='Email Address'
                />
                <Field
                    name='password'
                    component={TextInput}
                    type='password'
                    placeholder='Password'
                />
                {error && <Label basic color='red'>{error}</Label>}
                <Button fluid size='large' color='teal'>
                    Login
                </Button>
                <div className={"text-right text-primary"} onClick={() => openModal("ResetPasswordModal")}>Forgot Password</div>
                <Divider horizontal>Or</Divider>
                <SocialLogin socialLogin={socialLogin}/>
            </Segment>
        </Form>
    );
};

export default connect(
    null,
    actions
)(reduxForm({form: 'loginForm', validate})(LoginForm));
