import {auth, firestore} from "../../../config/firebase";
import {asyncActionError, asyncActionFinish, asyncActionStart} from "../../async/AsyncActions";
import {
    ADD_POSTS,
    NO_MORE_POSTS,
    RESET_USER_POSTS,
    SET_PROFILE_SCROLL_POSITION,
    USER_LIKED_POSTS_FETCH_SUCCESS,
    USER_POSTS_FETCH_SUCCESS,
    USER_PROFILE_FETCH_FAILURE,
    USER_PROFILE_FETCH_SUCCESS
} from "../../../actions/types";
import {fetcher, getUserId} from "../../../utilities/utils";
import {toastr} from 'react-redux-toastr';
import {closeModal} from "../../../features/modals/modalActions";
import {collection, doc, getDoc} from "firebase/firestore/lite";
import * as Sentry from "@sentry/node";


export const setScrollPosition = scrollPosition => async (dispatch) => {
    dispatch({
        type: SET_PROFILE_SCROLL_POSITION,
        value: scrollPosition
    });
};

export const getUserProfile = (userId) => async (dispatch) => {
    const userRef = doc(collection(firestore, 'users'), userId);

    try {
        let doc = await getDoc(userRef)
        if (doc.exists) {
            let userData = doc.data()
            dispatch({type: USER_PROFILE_FETCH_SUCCESS, payload: userData})
        } else {
            dispatch({type: USER_PROFILE_FETCH_FAILURE})
        }
    } catch (e) {
        dispatch({type: USER_PROFILE_FETCH_FAILURE})
    }

}

export const getUserPosts = (isRefresh, userId = getUserId()) => async (dispatch, getState) => {
    if (!getState().async.loading) {
        try {
            dispatch(asyncActionStart());
            let lastVisibleCursor = getState().profile.lastUserPostsCursor;
            const baseUrl = `https://naija-world-1c442.web.app/api/userPosts?userId=${userId}`;
            const url = isRefresh ? baseUrl : `${baseUrl}&cursor=${lastVisibleCursor}`;

            const response = await fetcher(url) || [];
            const {data: posts, nextCursor: cursor} = response;

            if (posts.length > 0) {
                dispatch({type: ADD_POSTS, payload: posts});
                dispatch({type: USER_POSTS_FETCH_SUCCESS, cursor, hasMore: Boolean(cursor)});
            } else {
                dispatch({type: NO_MORE_POSTS})
            }
            dispatch(asyncActionFinish());
        } catch (error) {
            Sentry.captureException(error);
            dispatch(asyncActionError());
        }
    }
};

export const resetUserPosts = () => async (dispatch) => {
    dispatch({type: RESET_USER_POSTS});
};

export const getLikedPosts = (isRefresh, userId) => async (dispatch, getState) => {
    if (!getState().async.loading) {
        try {
            dispatch(asyncActionStart());
            let lastVisibleCursor = getState().profile.lastLikedPostsCursor;
            const baseUrl = `https://naija-world-1c442.web.app/api/likedPosts?userId=${userId}`;
            const url = isRefresh ? baseUrl : `${baseUrl}&cursor=${lastVisibleCursor}`;

            const response = await fetcher(url) || [];
            const {data: posts, nextCursor: cursor} = response;

            if (posts.length > 0) {
                dispatch({type: ADD_POSTS, payload: posts});
                dispatch({type: USER_LIKED_POSTS_FETCH_SUCCESS, cursor, hasMore: Boolean(cursor)});
            } else {
                dispatch({type: NO_MORE_POSTS})
            }
            dispatch(asyncActionFinish());
        } catch (error) {
            Sentry.captureException(error);
            dispatch(asyncActionError());
        }
    }
};

export const updateProfile = user => async (
    dispatch,
) => {
    try {
        dispatch(asyncActionStart());
        const {username, gender, bio, dob} = user
        const token = await auth.currentUser?.getIdToken(true);
        const bearer = 'Bearer ' + token;

        const res = await fetch(`https://naija-world-1c442.web.app/api/users/${user.uid}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': bearer
            },
            body: JSON.stringify({
                user: {
                    username,
                    gender,
                    bio,
                    dob
                }
            })
        })
        if (res.ok) {
            toastr.success('Success', 'Your profile has been updated');
        } else {
            toastr.warning('Failed', 'Your profile was not updated');
            console.error("Can't update profile", res.status);
        }
        dispatch(closeModal())
        dispatch(asyncActionFinish());
    } catch (error) {
        console.error("Can't update profile", error)
        Sentry.captureException(error);
        dispatch(asyncActionError())
    }
};


export const updateProfilePic = imageFile => async (
    dispatch,
) => {
    try {
        dispatch(asyncActionStart());
        const user = auth.currentUser;
        const token = await user?.getIdToken(true);
        const bearer = 'Bearer ' + token;
        let formData = new FormData();
        formData.append('image', imageFile);
        try {
            const res = await fetch(`https://naija-world-1c442.web.app/api/profile-pic/${user.uid}`, {
                method: 'PUT',
                headers: {
                    'Authorization': bearer
                },
                body: formData
            })
            if (res.ok) {
                toastr.success('Success', 'Your profile pic has been updated');
            } else {
                toastr.warning('Failed', 'Your profile pic was not updated');
                console.error("Can't update profile pic", res.status);
            }
        } catch (error) {
            console.error("Can't update profile pic", error)
            Sentry.captureException(error);
        }
        dispatch(asyncActionFinish())
        dispatch(closeModal())
    } catch (error) {
        Sentry.captureException(error);
        dispatch(asyncActionError())
    }
};