import React from 'react';
import {connect} from 'react-redux';
import LoginForm from '../auth/Login/LoginForm';
import {closeModal} from './modalActions';
import Modal from "react-bootstrap/Modal";

const actions = {closeModal};

const LoginModal = ({closeModal}) => {
    return (
        <Modal show={true} onHide={closeModal} centered>
            <Modal.Header>
                <Modal.Title>
                    Login to NaijaWorld
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LoginForm/>
            </Modal.Body>
        </Modal>
    );
}

export default connect(
    null,
    actions
)(LoginModal);
