import React from 'react';
import {connect} from 'react-redux';
import {closeModal} from './modalActions';
import RegisterForm from '../auth/Register/RegisterForm';
import {Modal} from "react-bootstrap";

const actions = {closeModal};

const RegisterModal = ({closeModal}) => {
    return (
        <Modal size='mini' show={true} onHide={closeModal} centered>
            <Modal.Header>
                <Modal.Title>
                    Sign Up to NaijaWorld!
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <RegisterForm/>
            </Modal.Body>
        </Modal>
    );
}

export default connect(
    null,
    actions
)(RegisterModal);
