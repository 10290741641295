import {reset, SubmissionError} from 'redux-form';
import {closeModal} from '../modals/modalActions';
import {toastr} from 'react-redux-toastr';
import {auth, firestore} from "../../config/firebase";
import {asyncActionError, asyncActionFinish, asyncActionStart} from "../../screens/async/AsyncActions";
import {
    LOGIN_USER_FAIL,
    LOGIN_USER_SUCCESS,
    RESET_PASSWORD_BEGIN,
    RESET_PASSWORD_FAIL,
    RESET_PASSWORD_SUCCESS,
    SIGNUP_USER_FAIL,
    SIGNUP_USER_SUCCESS
} from "../../actions/types";
import get from 'lodash/get';
import * as Sentry from '@sentry/node'
import {
    createUserWithEmailAndPassword,
    FacebookAuthProvider,
    getAdditionalUserInfo,
    GoogleAuthProvider,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signInWithPopup,
    updateProfile
} from "firebase/auth";
import {doc, serverTimestamp, setDoc} from "firebase/firestore/lite";

export const login = creds => {
    return async (dispatch) => {
        dispatch(asyncActionStart());
        try {
            await signInWithEmailAndPassword(auth, creds.email, creds.password);
            dispatch({type: LOGIN_USER_SUCCESS});
            dispatch(asyncActionFinish());
            dispatch(closeModal());
        } catch (error) {
            Sentry.captureException(error);
            dispatch({type: LOGIN_USER_FAIL, payload: error});
            dispatch(asyncActionFinish());
            throw new SubmissionError({
                _error: "there is an issue with logging in"
            });
        }
    };
};

export const registerUser = user => async (
    dispatch
) => {
    try {
        dispatch(asyncActionStart());

        let createdUser = await createUserWithEmailAndPassword(auth, user.email, user.password);
        await updateProfile(createdUser.user.auth.currentUser, {
            displayName: user.username
        });
        // fixme auth issues on web
        const userRef = doc(firestore, 'users', createdUser.user.uid)
        await setDoc(userRef, {
            username: user.username,
            email: user.email,
            dob: user.dateOfBirth,
            // gender: user.gender,
            points: 1,
            followCnt: 0,
            notificationCnt: 0,
            overallPosition: 0,
            ftc: 0,
            createdAt: serverTimestamp()
        });

        dispatch({type: SIGNUP_USER_SUCCESS});
        dispatch(asyncActionFinish());
        dispatch(closeModal());

    } catch (error) {
        Sentry.captureException(error);
        dispatch({type: SIGNUP_USER_FAIL, payload: error});
        dispatch(asyncActionError());
        throw new SubmissionError({
            _error: "There is an issue with registering"
        });
    }
};

export const socialLogin = (selectedProvider) =>
    async (dispatch) => {
        const providers = {
            facebook: new FacebookAuthProvider(),
            google: new GoogleAuthProvider()
        }
        try {
            dispatch(closeModal())
            let provider = providers[selectedProvider]
            provider.addScope('https://www.googleapis.com/auth/user.birthday.read');
            provider.addScope('https://www.googleapis.com/auth/user.gender.read');
            const userCredential = await signInWithPopup(auth, provider);

            const {isNewUser} = getAdditionalUserInfo(userCredential)
            if (isNewUser) {
                const userRef = doc(firestore, 'users', userCredential.user.uid)
                await setDoc(userRef, {
                    email: userCredential.user.email,
                    dob: get(userCredential.user, 'birthday', null),
                    gender: get(userCredential.user, 'gender', 'male'),
                    username: userCredential.user.displayName,
                    photoURL: get(userCredential.user, 'photoURL', null),
                    points: 1,
                    followCnt: 0,
                    overallPosition: 0,
                    notificationCnt: 0,
                    ftc: 0,
                    createdAt: serverTimestamp(),
                    providerId: userCredential.providerId
                });
            }
        } catch (error) {
            Sentry.captureException(error);
        }
    }

export const resetPassword = (creds) => {
    return (dispatch) => {

        dispatch({type: RESET_PASSWORD_BEGIN});
        const resetPasswordSuccess = (dispatch) => {
            dispatch({type: RESET_PASSWORD_SUCCESS});
        };

        const resetPasswordFail = (dispatch, errorMessage) => {
            dispatch({type: RESET_PASSWORD_FAIL, payload: errorMessage});
        };

        sendPasswordResetEmail(auth, creds.email)
            .then(() => {
                resetPasswordSuccess(dispatch)
                toastr.success("Password Reset Successful", "Please check your email mailbox for instruction")
            })
            .catch((error) => {
                Sentry.captureException(error);
                switch (error.code) {
                    case "auth/invalid-email":
                        resetPasswordFail(dispatch, "The email address is not valid");
                        toastr.warning('Failed', 'The email address is not valid')
                        break;
                    case "auth/user-not-found":
                        resetPasswordFail(dispatch, "There is no user with that email address");
                        toastr.warning('Failed', 'The email address is not registered')
                        break;
                    default:
                        resetPasswordFail(dispatch, "Password reset failed, please try again");
                        toastr.warning('Failed', 'Password reset failed, please try again')
                }
            })
    }
};

export const updatePassword = (creds) =>
    async (dispatch) => {
        const user = auth.currentUser;
        try {
            await user.updatePassword(creds.newPassword1);
            await dispatch(reset('account'));
            toastr.success('Success', 'Your password has been updated')
        } catch (error) {
            Sentry.captureException(error);
            throw new SubmissionError({
                _error: error.message
            })
        }
    }