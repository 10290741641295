import React from 'react';
import {Button} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {closeModal, openModal} from './modalActions';
import {withRouter} from 'next/router';
import Modal from "react-bootstrap/Modal";

import 'semantic-ui-css/components/button.min.css'

const actions = {closeModal, openModal};

const UnauthModal = ({closeModal, openModal}) => {
    const handleCloseModal = () => {
        closeModal();
    }
    return (
        <Modal show={true} onHide={handleCloseModal}
               aria-labelledby="contained-modal-title-vcenter"
               centered>
            <Modal.Header closeButton>
                <Modal.Title>You need to be signed in to do that!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Please either login or register to see this page</p>
                <Button.Group widths={4}>
                    <Button
                        fluid
                        color='teal'
                        onClick={() => openModal('LoginModal')}
                    >
                        Login
                    </Button>
                    <Button.Or/>
                    <Button fluid positive onClick={() => openModal('RegisterModal')}>
                        Register
                    </Button>
                </Button.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleCloseModal}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default withRouter(connect(
    null,
    actions
)(UnauthModal));
