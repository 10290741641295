import React from 'react';
import {connect} from 'react-redux';
import {closeModal} from './modalActions';
import Modal from "react-bootstrap/Modal";
import ResetPasswordForm from "../auth/ResetPassword/ResetPasswordForm";

const actions = {closeModal};

const ResetPasswordModal = ({closeModal}) => {
    return (
        <Modal show={true} onHide={closeModal} centered>
            <Modal.Header>
                <Modal.Title>
                    Reset Password
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ResetPasswordForm/>
            </Modal.Body>
        </Modal>
    );
}

export default connect(
    null,
    actions
)(ResetPasswordModal);
