import React, {Fragment} from 'react';
import {Button, Icon} from 'semantic-ui-react';

import 'semantic-ui-css/components/button.min.css'
import 'semantic-ui-css/components/icon.min.css'

const SocialLogin = ({socialLogin}) => {
    return (
        <Fragment>
            {/*<Button*/}
            {/*    onClick={() => socialLogin('facebook')}*/}
            {/*    type='button'*/}
            {/*    style={{marginBottom: '10px'}}*/}
            {/*    fluid*/}
            {/*    color='facebook'*/}
            {/*>*/}
            {/*    <Icon name='facebook'/> Login with Facebook*/}
            {/*</Button>*/}

            <Button
                onClick={() => socialLogin('google')}
                type='button'
                fluid
                color='google plus'
            >
                <Icon name='google plus'/>
                Login with Google
            </Button>
        </Fragment>
    );
};

export default SocialLogin;
