import {ASYNC_ACTION_ERROR, ASYNC_ACTION_FINISH, ASYNC_ACTION_START, RESET_REDUCER_STATE} from "../../actions/types";

export const asyncActionStart = () => {
    return {
        type: ASYNC_ACTION_START
    }
};

export const asyncActionFinish = () => {
    return {
        type: ASYNC_ACTION_FINISH
    }
};

export const asyncActionError = () => {
    return {
        type: ASYNC_ACTION_ERROR
    }
};

export const resetReducerState = () => {
    return {
        type: RESET_REDUCER_STATE
    }
};

